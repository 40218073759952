import React from "react";
import { useSelector } from "react-redux";
import styles from "./CompanyEmployeeItem.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import ThreeDotsMenu from "../../ThreeDotsMenu/ThreeDotsMenu";
import CustomTooltip from "../../CompanyUserInfo/CustomTooltip";
import { ADMIN } from "../../../helpers/userRoles";

const CompanyEmployeeItem = ({ employeeInfo, status, role, onDelete, withMenu, onOpenChangeRolePopup, adminIndex, disableRoleChange }) => {
  const isAdmin = role === ADMIN;
  const userInfo = useSelector((state) => state.user);

  const getAdminNumber = () => {
    if (isAdmin && adminIndex !== null) {
      return adminIndex.toString();
    }
    return null;
  };

  const menu = [
    {
      name: "Delete",
      click: () => {
        onDelete(employeeInfo);
      },
    },
    {
      name: "Change Role",
      click: () => {
        onOpenChangeRolePopup(employeeInfo);
      },
    },
  ];

  const disabledMenu = [
    {
      name: "Delete",
      click: () => {
        onDelete(employeeInfo);
      },
    },
    {
      name: "Admin cap reached",
      click: () => { }
    },
  ]

  return (
    <div className={styles.employeeWrapper}>
      <div className={styles.employeeInfoContainerWithNameAndRole}>
        <div className={styles.nameContainer}>
          {employeeInfo.full_name?.length > 27 ? (
            <CustomTooltip name={employeeInfo.full_name}>
              <div className={styles.name}>{employeeInfo.full_name}</div>
            </CustomTooltip>
          ) : (
            <div className={styles.name}>{employeeInfo.full_name}</div>
          )}
        </div>
        {isAdmin && (
          <>
            <div className={styles.role}>Admin</div>
          </>
        )}
        {!isAdmin && (
          <div className={styles.role}>{employeeInfo.employee_role}</div>
        )}
      </div>

      <div className={styles.email}>{employeeInfo.email}</div>
      <div className={styles.statusWrapper}>
        <div
          className={classNames(styles.status, {
            [styles.hidden]: !status,
          })}
        >
          {status}
        </div>
        {withMenu && employeeInfo?.uuid !== userInfo?.uuid && (
          <div className={styles.threeDots}>
            <ThreeDotsMenu actions={disableRoleChange ? disabledMenu : menu} />
          </div>
        )}
      </div>
    </div>
  );
};

CompanyEmployeeItem.propTypes = {
  employeeInfo: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
    employee_role: PropTypes.string,
    uuid: PropTypes.string,
  }),
  status: PropTypes.string,
  role: PropTypes.string,
  onDelete: PropTypes.func,
  withMenu: PropTypes.bool,
  onOpenChangeRolePopup: PropTypes.func,
  team: PropTypes.array,
};

export default CompanyEmployeeItem;