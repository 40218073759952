import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { Breadcrumbs, Typography, Link } from "@material-ui/core"

import styles from "./VideoGalleryContainer.module.scss"
import VideoCard from "../../components/Videos/VideoCard/VideoCard"
import VideoPlayerPopup from "../../components/Videos/VideoPlayerPopup/VideoPlayerPopup"
import { withModal, Modal } from "../../context/modalContext"
import Button from "../../components/Button/Button"
import UploadMediaPopup from "../../components/Videos/UploadMediaPopup/UploadMediaPopup"
import useUploadVideo from "../../components/Videos/hooks/useUploadVideo"
import services from "../../services"
import { source } from "../../services/axios"
import { paths } from "../../routes/paths"
import { COMPANY_PROVIDER, COMPANY_SEEKER } from "../../helpers/userRoles"

const VideoGalleryContainer = ({
  context: { toggleModal, closeModal },
  selfGallery,
  id,
}) => {
  const userInfo = useSelector((state) => state.user)
  const [videos, setVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState()
  const [deleteVideoId, setDeleteVideoId] = useState()
  const [uploadPopup, setUploadPopup] = useState(false)
  const [userData, setData] = useState({})
  const [galleryType, setGalleryType] = useState()

  const params = useParams()

  const {
    uploadedVideos,
    onUploadVideo,
    uploadedVideosUrl,
    handlePopupMediaDelete,
    clearUploadedVideos,
    loading,
    setLoading,
    error,
    handleUploadMedia,
  } = useUploadVideo()

  const isCompany = galleryType === COMPANY_SEEKER || galleryType === COMPANY_PROVIDER

  const profileLink = selfGallery
    ? (isCompany ? "/company-profile" : "/professional-profile")
    : params.type === "companies"
    ? paths.selectedCopmanyProfile(id)
    : `/user/${id}`

  useEffect(() => {
    if (selfGallery) {
      services.userServices.getVideoGallery().then((result) => {
        setVideos([...result.data.videos])
        setGalleryType(result.data.type)
      })
    } else {
      services.userServices
        .getVideoGalleryByTypeAndId(params.type, params.id)
        .then((result) => {
          setVideos(result.data.videos)
          setData({ name: result.data.name })
        })
        .catch((error) => console.log(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadVideo = () => {
    handleUploadMedia().then((result) => {
      setVideos([...videos, ...result.data])
      setUploadPopup(false)
    })
  }

  const onDeleteVideo = (id) => {
    let newVideos = [...videos]
    services.userServices.deleteVideo(id).then(() => {
      newVideos = newVideos.filter((video) => video.uuid !== id)
      setVideos(newVideos)
    })
  }

  return (
    <div className={classNames("container", styles.videoGalleryContainer)}>
      <div className={styles.galleryContainerWrapper}>
        <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs}>
          <Link color="inherit" href={profileLink}>
            {selfGallery
              ? userInfo.role !== "individual"
                ? userInfo.company.name
                : isCompany
                ? userData.name
                : "My profile"
              : userData.name}
          </Link>
          <Typography color="textPrimary">Videos</Typography>
        </Breadcrumbs>
        <div
          className={classNames(styles.galleryWrapper, {
            [styles.galleryEmpty]: !videos.length,
          })}
        >
          <Modal
            id="deleteVideo"
            dialog
            subtitle="Do you want to delete this video?"
            button={
              <Button
                buttonStyle="black"
                onClick={() => {
                  onDeleteVideo(deleteVideoId)
                  closeModal()
                }}
              >
                Yes
              </Button>
            }
            acceptButton={
              <Button buttonStyle="outlinedBlack" onClick={() => closeModal()}>
                Cancel
              </Button>
            }
            closeAction={() => closeModal()}
            isCrossIcon={true}
          />

          <div className={styles.topLine}>
            <h5>Videos</h5>

            {selfGallery && (
              <Button
                onClick={() => {
                  setUploadPopup(true)
                }}
                className={styles.addVideosBtn}
              >
                Add video
              </Button>
            )}
          </div>

          <UploadMediaPopup
            visible={uploadPopup}
            uploadedMedias={uploadedVideos}
            galleryData={[]}
            error={error}
            title="Upload videos"
            acceptableFileTypes=".mp4, .m4v, .mov, .mpg, .mpeg"
            mediaType="video"
            loader={loading}
            handleUploadMedia={(e) => {
              onUploadVideo(videos, e)
            }}
            handleUpload={uploadVideo}
            handlePopupMediaDelete={handlePopupMediaDelete}
            handleCancel={() => {
              source.cancel()
              clearUploadedVideos()
              setUploadPopup(false)
              setLoading(0)
            }}
          />

          {selectedVideo && (
            <VideoPlayerPopup
              video={selectedVideo}
              closeAction={() => {
                setSelectedVideo()
              }}
              deleteVideo={(videoId) => {
                onDeleteVideo(videoId)
                setSelectedVideo()
              }}
              anotherUser={!selfGallery}
            />
          )}
          <div className={styles.videosWrapper}>
            {!videos.length > 0 && <p>You have not added any videos yet</p>}

            {videos.length > 0 &&
              videos.map((video) => (
                <VideoCard
                  key={video.id}
                  video={video}
                  // videoHeigh={200}
                  // videoWidth={270}
                  className={styles.cardWrapper}
                  onOpen={(openedVideo) => {
                    setSelectedVideo(openedVideo)
                  }}
                  deleteVideo={(videoId) => {
                    setDeleteVideoId(videoId)
                    toggleModal("deleteVideo")
                  }}
                  anotherUser={!selfGallery}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withModal(VideoGalleryContainer)
