export const GUEST = "guest"

export const ADMIN = "admin"
export const USER = "user"

export const COMPANY_SEEKER = "company_seeker"
export const COMPANY_PROVIDER = "company_provider"
export const INDIVIDUAL = "individual"

export const COMPANY_ADMIN = "company_admin"
export const COMPANY_EMPLOYEE = "company_employee"
export const ACCOUNT_MANAGER = "account_manager"
export const ASSOCIATE_COMPANY_ADMIN = "associate_company_admin"