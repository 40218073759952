export const roleTypes = [
  {
    type: "college_students",
    title: "College Student",
  },
  {
    type: "professionals",
    title: "Professional",
  },
  {
    type: "business_owners",
    title: "Business Owner",
  },
  {
    type: "board_members",
    title: "Board Member",
  },
]
export const activityTypes = [
  {
    type: "freshman",
    title: "Freshman",
  },
  {
    type: "sophomores",
    title: "Sophomore",
  },
  {
    type: "juniors_seniors",
    title: "Juniors Senior",
  },
  {
    type: "graduate_students",
    title: "Graduate Student",
  },
  {
    type: "freelancers",
    title: "Freelancer",
  },
  {
    type: "middle_managers",
    title: "Middle Manager",
  },
  {
    type: "recent_graduates",
    title: "Recent Graduate",
  },
  {
    type: "rising_executives",
    title: "Rising Executive",
  },
  {
    type: "senior_executives",
    title: "Senior Executive",
  },
  {
    type: "founders",
    title: "Founder",
  },
  {
    type: "mature",
    title: "Mature",
  },
  {
    type: "startups",
    title: "Startup",
  },
  {
    type: "board_of_directors",
    title: "Board of Directors",
  },
  {
    type: "diverse_company",
    title: "Diverse Company",
  },
  {
    type: "board_associates",
    title: "Board Advisors",
  },
]
export const professionTypes = [
  {
    id: 1,
    value: "Academia",
    databaseName: "academia",
  },
  {
    id: 2,
    value: "Agriculture & Environmental Services",
    databaseName: "agriculture_environmental_services",
  },
  {
    id: 3,
    value: "Business & Finance",
    databaseName: "business_finance",
  },
  {
    id: 4,
    value: "Construction",
    databaseName: "construction",
  },
  {
    id: 5,
    value: "Creative Arts & Design",
    databaseName: "creative_arts_design",
  },
  {
    id: 6,
    value: "Education & Training",
    databaseName: "education_training",
  },
  {
    id: 7,
    value: "Engineering & Technology",
    databaseName: "engineering_technology",
  },
  {
    id: 8,
    value: "Entertainment & Film",
    databaseName: "entertainment_film",
  },
  {
    id: 9,
    value: "Government & Public Administration",
    databaseName: "government_public_admin",
  },
  {
    id: 10,
    value: "Health & Medicine",
    databaseName: "health_medicine",
  },
  {
    id: 11,
    value: "Healthcare",
    databaseName: "healthcare",
  },
  {
    id: 12,
    value: "Hospitality & Tourism",
    databaseName: "hospitality_tourism",
  },
  {
    id: 13,
    value: "Law & Legal Professions",
    databaseName: "law_legal_professions",
  },
  {
    id: 14,
    value: "Law Enforcement",
    databaseName: "law_enforcement",
  },
  {
    id: 15,
    value: "Manufacturing & Production",
    databaseName: "manufacturing_production",
  },
  {
    id: 16,
    value: "Media & Marketing",
    databaseName: ":media_communications",
  },
  {
    id: 17,
    value: "Public Safety & Defense",
    databaseName: "public_safety_defense",
  },
  {
    id: 18,
    value: "Sales & Customer Service",
    databaseName: "sales_customer_service",
  },
  {
    id: 19,
    value: "Science & Research",
    databaseName: "science_research",
  },
  {
    id: 20,
    value: "Sports & Recreation",
    databaseName: "sports_recreation",
  },
  {
    id: 21,
    value: "Transportation & Logistics",
    databaseName: "transportation_logistics",
  }
]

export const experienceLevels = [
  {
    id: 1,
    value: "Entry",
    databaseName: "entry",
  },
  {
    id: 2,
    value: "Mid",
    databaseName: "mid",
  },
  {
    id: 3,
    value: "Senior",
    databaseName: "senior",
  },
  {
    id: 4,
    value: "Executive",
    databaseName: "executive",
  },
  {
    id: 5,
    value: "Expert/Specialist",
    databaseName: "expert_specialist",
  },
  {
    id: 5,
    value: "Board Member",
    databaseName: "board_member",
  },
]

export const genderTypes = [
  {
    id: "not_applicable_gender",
    value: "Prefer not to say",
  },
  {
    id: "male",
    value: "Male",
  },
  {
    id: "female",
    value: "Female",
  },
  {
    id: "non_binary",
    value: "Non-binary",
  },
  {
    id: "genderqueer",
    value: "Genderqueer",
  },
  {
    id: "transgender",
    value: "Transgender",
  },
  {
    id: "genderfluid",
    value: "Genderfluid",
  },
  {
    id: "agender",
    value: "Agender",
  },
  {
    id: "two_spirit",
    value: "Two-Spirit",
  },
  {
    id: "other",
    value: "Other",
  }
]
export const raceTypes = [
  {
    type: "black_african_american",
    title: "Black/African-American",
  },
  {
    type: "american_indian",
    title: "American Indian/Alaskan Native",
  },
  {
    type: "asian",
    title: "Asian",
  },
  {
    type: "native_hawaiian",
    title: "Native Hawaiian/Pacific Islander",
  },
  {
    type: "hispanic",
    title: "Hispanic/Latino",
  },
  {
    type: "white",
    title: "White",
  }
]
export const companyTypes = [
    {
          type: "false",
          title: "Companies/Organizations",
    },
    {
          type: "true",
          title: "Solopreneurs",
    },
]
export const levelTypes = [
  {
    type: "elite",
    title: "Elite",
  },
  {
    type: "elite_plus",
    title: "Elite Plus",
  },
  {
    type: "elite_premium",
    title: "Elite Premium",
  }
]
export const companyRoleTypes = [
  {
    type: "corporations",
    title: "Corporation",
  },
  {
    type: "financial_organizations",
    title: "Financial Services",
  },
  {
    type: "dc_education",
    title: "Education",
  },
  {
    type: "nonprofit",
    title: "Nonprofit",
  },
  {
    type: "diverse_business",
    title: "Diverse Business",
  },
]
export const companyActivityTypes = [
  {
    type: "small",
    title: "Small",
  },
  {
    type: "medium",
    title: "Medium",
  },
  {
    type: "large",
    title: "Large",
  },
  {
    type: "black_student_unions",
    title: "Black Student Union",
  },
  {
    type: "civic_organizations",
    title: "Civic Organization",
  },
  {
    type: "hbcus",
    title: "HBCU",
  },
  {
    type: "professional_organizations",
    title: "Professional Organization",
  },
  {
    type: "sororities_and_fraternities",
    title: "Sorority & Fraternity",
  },
  {
    type: "trade_associations",
    title: "Trade Association",
  },
  {
    type: "for_profit_board_of_directores_and_advisors",
    title: "For-profit Board of Directors & Advisors",
  },
  {
    type: "non_profit_board_of_directores_and_advisors",
    title: "Nonprofit Board of Directors & Advisors",
  },
  {
    type: "private_clubs_and_associations",
    title: "Private Clubs & Associations",
  },
  {
    type: "banking",
    title: "Banking",
  },
  {
    type: "angel_investors",
    title: "Angel investors",
  },
  {
    type: "incubators",
    title: "Debt Financing",
  },
  {
    type: "working_capital_financing",
    title: "Working Capital Financing",
  },
  {
    type: "mergers_and_acquisitions",
    title: "Merger & Acquisition",
  },
  {
    type: "private_acquity",
    title: "Private Equity",
  },
  {
    type: "wealth_management",
    title: "Wealth Management",
  },
  {
    type: "venture_capital",
    title: "Venture Capital",
  },
  {
    type: "businesses",
    title: "Business",
  },
  {
    type: "executives",
    title: "Executive",
  },
  {
    type: "professionals",
    title: "Professional",
  },
  {
    type: "students",
    title: "Student",
  },
  {
    type: "chief_operating_officers",
    title: "Chief Operating Officer",
  },
  {
    type: "chief_procurement_officers",
    title: "Chief Procurement Officer",
  },
  {
    type: "logistic_executives",
    title: "Logistics Executive",
  },
  {
    type: "operations_executives",
    title: "Operations Executive",
  },
  {
    type: "supplier_diversity_managers",
    title: "Supplier Diversity Manager",
  },
  {
    type: "diversity_and_inclusion",
    title: "Diversity & Inclusion",
  },
  {
    type: "officers",
    title: "Officer",
  },
  {
    type: "hr_practitioners",
    title: "HR Practitioner",
  },
  {
    type: "hiring_managers",
    title: "Hiring Manager",
  },
  {
    type: "supplier_diversity",
    title: "Supplier Diversity",
  },
  {
    type: "managers",
    title: "Manager",
  },
  {
    type: "vp_engineering",
    title: "VP, Engineering",
  },
  {
    type: "vp_product_marketing",
    title: "VP, Product Marketing",
  },
]
export const companyIndustryTypes = [
  {
    type: "administrative_services",
    title: "Administrative Services",
  },
  {
    type: "airlines",
    title: "Airlines",
  },
  {
    type: "advertising",
    title: "Advertising",
  },
  {
    type: "apparel",
    title: "Apparel",
  },
  {
    type: "arts_entertainment_and_recreation",
    title: "Arts, Entertainment & Recreation",
  },
  {
    type: "beauty",
    title: "Beauty",
  },
  {
    type: "broadcasting",
    title: "Broadcasting (except internet)",
  },
  {
    type: "construction",
    title: "Construction",
  },
  {
    type: "data_processing_hosting_and_related_services",
    title: "Data processing, hosting & related services",
  },
  {
    type: "education",
    title: "Education",
  },
  {
    type: "financial_services_and_insurance",
    title: "Financial Services & Insurance",
  },
  {
    type: "food_services",
    title: "Food Services",
  },
  {
    type: "governance_strategic_development",
    title: "Governance & Strategic Development",
  },
  {
    type: "government",
    title: "Government",
  },
  {
    type: "healthcare",
    title: "Healthcare",
  },
  {
    type: "human_resources",
    title: "Human Resources",
  },
  {
    type: "infrastructure",
    title: "Infrastructure",
  },
  {
    type: "it_digital",
    title: "Information Technology & Digital",
  },
  {
    type: "legal",
    title: "Legal",
  },
  {
    type: "leisure_and_hospitality",
    title: "Leisure & Hospitality",
  },
  {
    type: "distribution",
    title: "3PL & Distribution",
  },
  {
    type: "manufacturing",
    title: "Product Development & Manufacturing",
  },
  {
    type: "management_of_companies_and_enterprises",
    title: "Management of Companies & Enterprise",
  },
  {
    type: "marketing",
    title: "Marketing & Communications",
  },
  {
    type: "media",
    title: "Media",
  },
  {
    type: "professional_and_business_services",
    title: "Professional Business Services & Consulting",
  },
  {
    type: "professional_scientific_and_technical_services",
    title: "Professional, Scientific & Technical Services",
  },
  {
    type: "public_relations",
    title: "Public Relations",
  },
  {
    type: "real_estate",
    title: "Real Estate",
  },
  {
    type: "retail_trade",
    title: "Retail Trade",
  },
  {
    type: "software_development",
    title: "Software Development",
  },
  {
    type: "technology",
    title: "Technology",
  },
  {
    type: "telecommunications",
    title: "Telecommunications",
  },
  {
    type: "transportation",
    title: "Transportation",
  },
  {
    type: "wholesale_trade",
    title: "Wholesale Trade",
  },
]
export const companyDepartmentTypes = [
  {
    type: "finance_and_accounting",
    title: "Finance & Accounting",
  },
  {
    type: "human_resources",
    title: "Human Resources",
  },
  {
    type: "procurement_or_purchasing",
    title: "Procurement/Purchasing",
  },
  {
    type: "business_development_and_sales",
    title: "Business Development & Sales",
  },
  {
    type: "marketing_and_public_relations",
    title: "Marketing & Public Relations",
  },
  {
    type: "administration_and_management",
    title: "Administration & Management",
  },
  {
    type: "operations",
    title: "Operations",
  },
  {
    type: "technology",
    title: "Technology",
  },
  {
    type: "customer_service_support",
    title: "Customer Service Support",
  },
  {
    type: "legal",
    title: "Legal",
  },
  {
    type: "distribution",
    title: "Distribution",
  },
  {
    type: "research_and_development",
    title: "Research & Development",
  },
]
export const companyDiverseType = [
  {
    title: "Yes",
    type: "yes",
  },
  {
    title: "No",
    type: "no",
  },
]
export const companyCertifiedType = [
    {
        title: "Yes",
        type: "true",
    },
    {
        title: "No",
        type: "false",
    },
]
