import React from "react"
import Popup from "../../../components/Popup/Popup"
import CompanyEmployeeForm from "../../../forms/CompanyEmployeeForm/CompanyEmployeeForm"

const CompanyEmployeeCreatorPopup = ({
  data,
  closeAction,
  title = "Invite employee",
  onSubmit,
  setVisiblePopup,
  disableAdmin,
}) => {
  return (
    <Popup
      width={496}
      title={title}
      isCrossIcon
      closeAction={closeAction}
      closeByOutsideClick={false}
    >
      <CompanyEmployeeForm
        data={data}
        closeAction={closeAction}
        onSubmit={onSubmit}
        setVisiblePopup={setVisiblePopup}
        disableAdmin={disableAdmin}
      />
    </Popup>
  )
}

export default CompanyEmployeeCreatorPopup
