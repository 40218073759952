import React from "react";
import Popup from "../../../components/Popup/Popup";
import Button from "../../../components/Button/Button";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { ADMIN, USER } from "../../../helpers/userRoles";
import styles from "./changeRole.module.scss"

const CompanyEmployeeChangeRolePopup = ({ closeAction, onSubmit, selectedRole, onRoleChange }) => {
  return (
    <Popup
      dialog
      title="Choose New Role"
      titleStyle={{
        textAlign: 'left',
        fontSize: '18px',
        color: 'rgba(0, 0, 0, 0.87)'
      }}
      popupHeaderStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }}
      childrenWrapperStyle={{
        padding: '24px'
      }}
      buttonsStyle={{
        margin: '0px'
      }}
      button={
        <Button buttonStyle="black" onClick={onSubmit}>
          <span style={{ fontSize: '14px' }}>Apply</span>
        </Button>
      }
      acceptButton={
        <Button buttonStyle="outlinedBlack" onClick={closeAction}>
          <span style={{ fontSize: '14px' }}>Cancel</span>
        </Button>
      }
      closeAction={closeAction}
      isCrossIcon={true}
    >
      <div className={styles.justifyCheckBoxes}>
        <RadioButton
          inputClassName={styles.myCustomInputClass}
          labelClassName={styles.myCustomLabelClass}
          titleClassName={styles.myCustomTitleClass}
          name="employee_role"
          label="User"
          value={USER}
          checked={selectedRole === USER}
          onChange={(e) => onRoleChange(e.target.value)}
        />
        <RadioButton
          inputClassName={styles.myCustomInputClass}
          labelClassName={styles.myCustomLabelClass}
          titleClassName={styles.myCustomTitleClass}
          name="employee_role"
          label="Admin"
          value={ADMIN}
          checked={selectedRole === ADMIN}
          onChange={(e) => onRoleChange(e.target.value)}
        />
      </div>
    </Popup>
  );
};

export default CompanyEmployeeChangeRolePopup;