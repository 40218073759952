import React from "react"
import classNames from "classnames"
import { object, string } from "yup"
import { withFormik } from "formik"

import Input from "../../components/Input/Input"
import styles from "./CompanyEmployeeForm.module.scss"
import Button from "../../components/Button/Button"
import { isHaveBadWords } from "../../helpers/badWords"
import RadioButton from "../../components/RadioButton/RadioButton"
import { USER, ADMIN} from "../../helpers/userRoles"

const CompanyEmployeeForm = ({
  data = {},
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  closeAction,
  disableAdmin,
}) => {
  const isNew = Object.keys(data).length === 0

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit}>
      <div className={styles.fieldsWrapper}>
        <Input
          value={values.fullName}
          label="Full name"
          name="fullName"
          placeholder="Enter full name"
          fieldStyle="light"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.fullName && touched.fullName}
          errorMessage={errors.fullName && touched.fullName && errors.fullName}
        />

        <Input
          value={values.email}
          label="Email"
          name="email"
          placeholder="Enter email"
          fieldStyle="light"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email}
          errorMessage={errors.email && touched.email && errors.email}
        />

        <p className={styles.roleSelectionTitle}>Choose Role:</p>
        <div className={styles.radioGroup}>
          <RadioButton
            buttonStyle="black"
            className={styles.chooseRole}
            labelClassName={styles.myCustomLabelClass}
            titleClassName={styles.myCustomTitleClass}
            name="employee_role"
            label="User"
            value={USER}
            onChange={(e) => handleChange("role")(e.target.value)}
            checked={values.role === USER}
          />

          <RadioButton
            buttonStyle="black"
            className={styles.chooseRole}
            labelClassName={styles.myCustomLabelClass}
            titleClassName={styles.myCustomTitleClass}
            name="employee_role"
            label="Admin"
            value={ADMIN}
            disabled={disableAdmin}
            onChange={(e) => handleChange("role")(e.target.value)}
            checked={values.role === ADMIN}
          />
        </div>
      </div>
      {disableAdmin && ( <div className={styles.adminCapReached}>Admin limit has been reached</div>)}
      <div
        className={classNames(styles.btsWrapper, {
          [styles.oneButtonWrapper]: isNew,
        })}
      >
        {!isNew && (
          <Button buttonStyle="outlinedBlack" onClick={closeAction}>
            Cancel
          </Button>
        )}

        <Button
          buttonStyle="black"
          onClick={handleSubmit}
          disabled={
            !values.fullName.trim() ||
            !values.email.trim() ||
            !values.role ||
            (values.role !== USER && values.role !== ADMIN) ||
            Object.values(errors).length > 0
          }
        >
          {isNew ? "Invite" : "Save"}
        </Button>
      </div>
    </form>
  )
}

export default withFormik({
  mapPropsToValues: ({ data }) => {
    if (!!data) {
      return { fullName: data.full_name, email: data.email, role: data?.employee_role || null, }
    }
    return { fullName: "", email: "", role: null }
  },
  validationSchema: () => {
    return object().shape({
      fullName: string()
        .trim()
        .matches(
          /^[a-zA-Z\s_\-_'_"]*$/,
          "Enter a correct Full Name that doesn't contain any special characters or numbers",
        )
        .min(
          2,
          "Please enter a correct full name that consists of a minimum 2 characters",
        )
        .max(
          32,
          "Please enter a correct full name that consists of no more than 32 characters",
        )
        .test({
          name: "description_obj",
          test: (val) => {
            if (!val) return true
            if (val) {
              return !isHaveBadWords(val)
            }
          },
          message: `Please don't use bad language`,
        })
        .required("Please fill the name"),
      email: string()
        .email("Please enter your email address in format yourname@example.com")
        .max(
          200,
          "Please enter a valid email address that consists of no more than 200 characters",
        )
        .test({
          name: "description_obj",
          test: (val) => {
            if (!val) return true
            if (val) {
              return !isHaveBadWords(val)
            }
          },
          message: `Please don't use bad language`,
        })
        .required("Please enter email address"),
      role: string()
        .oneOf([USER, ADMIN], "Invalid role selected")
        .required("Role is required"),
    })

  },
  handleSubmit: (
    values,
    {
      setSubmitting,
      setFieldError,
      props: { onSubmit, closeAction, setVisiblePopup },
    },
  ) => {
    onSubmit(values)
      .then(() => {
        closeAction()
        setVisiblePopup("invited")
      })
      .catch((e) => setFieldError("email", e.response.data.error))
    setSubmitting(false)
  },
})(CompanyEmployeeForm)
