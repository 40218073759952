import React, { useState, useEffect } from "react";
import styles from "./CompanyEmployeesContainer.module.scss";
import { useSelector } from "react-redux";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { paths } from "../../routes/paths";
import Button from "../../components/Button/Button";
import CompanyEmployeeItem from "../../components/elements/CompanyEmployeeItem/CompanyEmployeeItem";
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth";
import CompanyEmployeeCreatorPopup from "./popups/CompanyEmployeeCreatorPopup";
import CompanyEmployeeDeletePopup from "./popups/CompanyEmployeeDeletePopup";
import CustomTooltip from "../../components/CompanyUserInfo/CustomTooltip";
import services from "../../services";
import { ADMIN, COMPANY_ADMIN, USER } from "../../helpers/userRoles";
import CompanyEmployeeInvitedPopup from "./popups/CompanyEmployeeInvitedPopup";
import Typography from "@material-ui/core/Typography";
import { isHaveBadWords } from "../../helpers/badWords";
import CompanyEmployeeChangeRolePopup from "./popups/CompanyEmployeeChangeRolePopup";
import ThreeDotsMenu from "../../components/ThreeDotsMenu/ThreeDotsMenu";

const CompanyEmployeesContainer = () => {
  const userInfo = useSelector((state) => state.user);
  const { width } = useWindowHeightWidth();
  const [visiblePopup, setVisiblePopup] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [companyAdmin, setCompanyAdmin] = useState(null);
  const [team, setTeam] = useState([]);
  const [companyProfile, setCompanyProfile] = useState({});
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    services.employeeServices
      .getEmployees()
      .then((result) => updateTeamInfo(result))
      .catch((e) => console.log(e));
    services.companyServices
      .getSelfCompanyInfo()
      .then(({ data }) => {
        const companyProfile = data;
        setCompanyProfile(companyProfile);
        return companyProfile;
      });
  }, []);

  const updateTeamInfo = (result) => {
    setTeam(result.data.team);
    setCompanyAdmin(result.data.admin);
  }

  const closePopup = () => {
    setVisiblePopup("");
    setSelectedEmployee("");
  };

  const handleSubmit = async ({ email, fullName, role }) => {
    try {
      const result = await services.employeeServices.createEmployee({
        email,
        full_name: fullName,
        employee_role: role,
      });
      setTeam((prevTeam) => [...prevTeam, result.data]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleChangeRole = async (role) => {
    try {
      const { id } = selectedEmployee;
      const employee_role = role;
      await services.employeeServices.setNewRoleEmployee(id, employee_role);
      setTeam((prevTeam) =>
        prevTeam.map((member) =>
          member.user.uuid === selectedEmployee.user.uuid
            ? { ...member, employee_role: role }
            : member
        )
      );
      closePopup();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = () => {
    const { uuid } = selectedEmployee;
    services.employeeServices.deleteEmployee(uuid).then(() => {
      const newTeam = team.filter((member) => member.user.uuid !== uuid);
      setTeam(newTeam);
      closePopup();
    });
  };

  const getAdminCount = () => {
    return team?.filter(member => member.employee_role === ADMIN).length;
  };

  const isAdmin = () => {
    if (userInfo.role === COMPANY_ADMIN) {
      return true;
    }
    else {
      const idx = team?.findIndex((member) => member.user.uuid === userInfo.uuid && member.employee_role === ADMIN) >= 0;
      return idx;
    }
  };

  return (
    <div>
      <div className={styles.employeesContainer}>
        <Breadcrumbs className={styles.breadcrumbs}>
          <Link
            to={paths.selectedCopmanyProfile(userInfo.company.uuid)}
            className={styles.link}
          >
            {userInfo.company.name}
          </Link>
          <Typography
            // to={paths.departments(params.uuid)}
            className={classNames(styles.link, styles.currentLink)}
          >
            My Team
          </Typography>
        </Breadcrumbs>

        <div className={styles.employeesBox}>
          <div className={styles.topInfo}>
            <h5>My Team</h5>
            {isAdmin() && (
              <CustomTooltip
                text={
                  companyProfile?.max_seats_allowed
                    ? `You use ${team.length} of ${companyProfile?.max_seats_allowed} available seats`
                    : null
                }
              >
                <span>
                  <Button
                    onClick={() => {
                      setVisiblePopup("new");
                    }}
                    width={width < 451 ? "64px" : "186px"}
                    height="40px"
                    disabled={
                      companyProfile?.max_seats_allowed !== null &&
                      team.length >= companyProfile?.max_seats_allowed
                    }
                  >
                    {width < 451 ? "Add" : "Add Employee"}
                  </Button>
                </span>
              </CustomTooltip>
            )}
          </div>
          {/* check for user in admin list, and add controls if user has "admin" employee role */}
          {isAdmin && team.length === 0 && (
            <div className={styles.adminBlock}>
              <div className={styles.infoBlock}>
                <div className={styles.nameStyle}>{companyAdmin?.full_name}</div>
                <div className={styles.roleStyle}>Primary Admin*</div>
              </div>
              <div className={styles.emailBlock}>
                {companyAdmin?.email}
              </div>
              <div className={styles.emptyBlock}></div>

            </div>
          )}

          <>
            {team.length > 0 ? (
              team
                .slice()
                .sort((a, b) => {
                  const roleA = a.employee_role;
                  const roleB = b.employee_role;
                  if (roleA === ADMIN && roleB === ADMIN) {
                    if (userInfo.uuid === a.user.uuid) {
                      return -1;
                    }
                    else if (userInfo.uuid === b.user.uuid) {
                      return 1;
                    }
                    else {
                      return 0;
                    }
                  }
                  else if (roleA === ADMIN) {
                    return -1;
                  }
                  else if (roleB === ADMIN) {
                    return 1;
                  }
                  else {
                    return 0;
                  }
                })
                .map((employee, index) => (
                  <CompanyEmployeeItem
                    key={employee.uuid}
                    employeeInfo={employee.user}
                    status={getStatus(employee.status)}
                    role={employee.employee_role}
                    withMenu={isAdmin()}
                    onOpenChangeRolePopup={() => {
                      setSelectedEmployee(employee);
                      setVisiblePopup("role");
                    }}
                    onDelete={(employeeInfo) => {
                      setSelectedEmployee(employeeInfo);
                      setVisiblePopup("delete");
                    }}
                    adminIndex={employee.employee_role === ADMIN ? index + 1 : null}
                    disableRoleChange={getAdminCount() >= 2 && employee.employee_role === USER}
                  />
                ))
            ) : (
              <div className={styles.emptyPage}>
                <p>There are no company members yet.</p>
              </div>
            )}
          </>
        </div>
      </div>
      {visiblePopup === "new" && (
        <CompanyEmployeeCreatorPopup
          data={selectedEmployee}
          closeAction={closePopup}
          onSubmit={handleSubmit}
          setVisiblePopup={setVisiblePopup}
          disableAdmin={getAdminCount() >= 2}
        />
      )}
      {visiblePopup === "delete" && (
        <CompanyEmployeeDeletePopup
          closeAction={closePopup}
          onDelete={handleDelete}
        />
      )}
      {visiblePopup === "role" && (
        <CompanyEmployeeChangeRolePopup
          setVisiblePopup={setVisiblePopup}
          onRoleChange={setSelectedRole}
          closeAction={closePopup}
          selectedRole={selectedRole}
          onSubmit={() =>
            handleChangeRole(selectedRole, selectedEmployee?.user.uuid)
          }
        />
      )}
      {visiblePopup === "invited" && (
        <CompanyEmployeeInvitedPopup
          closeAction={closePopup}
          onDelete={handleDelete}
          data={team[team.length - 1]?.user?.email}
        />
      )}
    </div>
  );
};

const getStatus = (status) => {
  switch (status) {
    case "invite_sent":
      return "Invite sent";
    default:
      return null;
  }
};

export default CompanyEmployeesContainer;
