import React from "react"
import { Route, Redirect, withRouter } from "react-router-dom"
import { useSelector } from "react-redux"

import {
  GUEST,
  INDIVIDUAL,
  ADMIN,
  COMPANY_ADMIN,
  COMPANY_EMPLOYEE,
  ACCOUNT_MANAGER,
  ASSOCIATE_COMPANY_ADMIN,
} from "./userRoles"
import { paths } from "../routes/paths"
import Loading from "../components/Loading/Loading"

const RouteWithSubRoutes = ({ allowed, exact, path, routes, Component }) => {
  const userInfo = useSelector((state) => state.user)

  if (
    !allowed.includes(userInfo.role) &&
    !allowed.includes(userInfo.company?.type)
  ) {
    switch (userInfo.role) {
      case GUEST:
        return <Redirect to={paths.login} />
      case ACCOUNT_MANAGER:
        return <Redirect to={paths.chats('')} />
      case COMPANY_ADMIN:
      case COMPANY_EMPLOYEE:
      case ASSOCIATE_COMPANY_ADMIN:
        return <Redirect to={paths.companyProfile} />
      case INDIVIDUAL:
        return <Redirect to={paths.individualProfile} />
      case ADMIN:
        return <Redirect to={paths.adminDashboard} />
      default:
        return <Redirect to={paths.login} />
    }
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <Route
        exact={exact}
        path={path}
        render={(props) => <Component {...props} routes={routes} />}
      />
    </React.Suspense>
  )
}

export default withRouter(RouteWithSubRoutes)
